import { FlowWrapper } from '@arcadehq/shared/types'
import { useMemo } from 'react'
import useAccount from 'src/auth/useAccount'
import { Flow } from 'src/types'
import { theme } from 'tailwind.config'

import { CurrentFlowContext } from './types'

// internal hook. do not use in UI components

export function useFlowProviderContext(
  flow: Flow,
  isReadonly: boolean
): CurrentFlowContext {
  const account = useAccount()

  // Add 'Noto Color Emoji' as fallback for Emojis
  // This will be used during GIF/Video exports
  const fontFamily = useMemo(
    () =>
      `${
        (flow.font || account.flowDefaults.font) ?? theme.extend.fontFamily.sans
      }, "Noto Color Emoji"`,
    [flow.font, account.flowDefaults.font]
  )

  return {
    flow,
    isReadonly,
    hasBackground: !!flow.bgImage?.url,
    hasWrapper: flow.flowWrapper !== FlowWrapper.none,
    hasOverlay: flow.showStartOverlay,
    fontFamily,
  }
}

import { createContext, PropsWithChildren } from 'react'
import { Flow } from 'src/types'

import { CurrentFlowContext } from './types'

// see src/flow/withFlow.tsx for details

export const FlowContext = createContext<CurrentFlowContext>({
  // this is the wrong type but it doesn't matter because FlowProvider is
  // initializing the flow with the server's data
  flow: {} as Flow,
  isReadonly: true,
  hasBackground: false,
  hasWrapper: false,
  hasOverlay: false,
  fontFamily: '',
})

export function FlowProvider(
  props: PropsWithChildren<{ contextProps: CurrentFlowContext }>
) {
  const { children, contextProps } = props

  return (
    <FlowContext.Provider value={contextProps}>{children}</FlowContext.Provider>
  )
}
